html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700;900&family=Poppins:wght@300;400;700;900&family=Raleway:wght@300;400;700;900&display=swap");

/* .informationButton {
  width: 5.21vw;
  height: 5.21vw;

  background: #ffffff;
  border-radius: 100%;
  box-shadow: 9px 9px 18.75px rgba(0, 0, 0, 0.3);
} */

.mainBody {
  width: 90vw;
  margin: 10px auto;
  display: flex;
}

.openContainer {
  box-sizing: border-box;
  padding: 50px;
  margin: 10px auto;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: -12px -12px 30px rgba(0, 0, 0, 0.2),
    12px 12px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(40px);
  border-radius: 16px;
}

.openContainerHeading1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.openContainerHeading2 {
  margin: 3.54vw 0vw 2.29vw;
}

.closeContainer {
  height: fit-content;
  width: fit-content;
  justify-content: start;
  font-size: 2vw;
  color: #fff;
  cursor: pointer;
}



.openContainerHeading1 h1 {
  margin: 0 5vw 0 0;
}

.openContainerHeading1 h1,
.openContainerHeading2 h1 {
  width: 20.7vw;
  height: 2.447vw;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 900;
  font-size: 2.085vw;
  line-height: 2.445vw;

  text-align: center;
  letter-spacing: 0.02em;

  text-transform: uppercase;
  color: #f05c5c;
}

.navigationInfo {
  width: 16.72vw;
  height: 13.335vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
}
.element{
  width: 80px ;
  height: 80px;
  background-color: rgb(255, 255, 255);
  border: 1px solid black;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-items: center;
  text-align: center;
  font-weight: 800;
  font-size: 30px;
  font-family: "Raleway";
  margin: 1px;
}
.up{
  margin-right: 50px;
}
.inner{
  margin:0 auto;
}

.lower_row{
  display: flex;
  align-items: center;
}

.navigationButton {
  width: 2.98vw;
  height: 2.98vw;

  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: 0.446vw;
  background: #ffffff;
  border-radius: 5.8px;
}

.navigationButton p {
  margin: 0;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 900;
  font-size: 1.8225vw;
  line-height: 1.8225vw;

  letter-spacing: 0.02em;

  color: #000000;
}

.navigationViews {
  margin-top: 1.515vw;
  padding: 1.05vw 7.35vw;
  width: 28.335vw;
  height: 4.425vw;

  background: rgba(255, 255, 255, 0.25);
  cursor: pointer;
  border-radius: 4px;
}

.navigationViews h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.56vw;
  line-height: 2.35vw;

  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  color: #000;
}
